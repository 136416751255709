import React, { useEffect, useState } from "react";
import queryString from "query-string";
import axios from 'axios';
import { Helmet } from "react-helmet";

import Layout from "@components/common/Layout"
import Top from "@components/blog/Top"
import Main from "@components/blog/Main"
import Column from "@components/blog/Column"

const Draft = ({ location }) => {
  const { contentId, draftKey } = queryString.parse(location.search);
  const [data, setData] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(`https://tmqudlml96.execute-api.ap-northeast-1.amazonaws.com/prod/blog/${contentId}?draftKey=${draftKey}`);
        console.log(res)
        setData({
          ...res.data,
          publishedAt: new Date().toISOString()
        });
      } catch(err) {
        console.log(err);
      }
    })();
  }, [contentId, draftKey]);

  if (data === null) {
    return null;
  }

  return (
    <Layout>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Top hero_image={data.hero_image} />
      <Main {...data} />
      {data.related_blogs.length > 0 &&
        <Column related_blogs={data.related_blogs} />
      }
    </Layout>
  )
}

export default Draft